<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-4">
        <h2>{{ $t("analytics.searches_without_results.title") }}</h2>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{ userConf.date_range.startDate | moment }} - {{ userConf.date_range.endDate | moment }}
        </span>
      </div>
      <div class="col-xl-8">
        <UsStatsConfiguration></UsStatsConfiguration>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-8">
        <div class="card card-custom gutter-b">
          <div class="card-header h-auto border-0">
            <div class="card-title">
              <h3 class="card-label">
                {{
                  $t("analytics.searches_without_results.list.timeline.title")
                }}
              </h3>
            </div>
            <div class="card-toolbar">
              <span class="text-danger font-weight-bolder font-size-h3">
                <i
                  class="fa fa-search text-danger font-weight-bolder font-size-h3 mr-2"
                ></i>
                {{
                  parseInt(
                    searches_without_results.totals &&
                      searches_without_results.totals.queries_wo_results
                      ? searches_without_results.totals.queries_wo_results
                      : 0
                  ).toLocaleString()
                }}
                &nbsp;/&nbsp;
                <i
                  class="fa fa-users text-danger font-weight-bolder font-size-h3 mr-2"
                ></i>
                {{
                  parseInt(
                    searches_without_results.totals &&
                      searches_without_results.totals.unique_queries_wo_results
                      ? searches_without_results.totals
                          .unique_queries_wo_results
                      : 0
                  ).toLocaleString()
                }}</span
              >
            </div>
          </div>
          <!--begin::Body-->
          <div class="card-body p-0">
            <div v-if="this.loading">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                style="margin:auto;background:#fff;display:block;"
                width="200px"
                height="200px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <g transform="translate(20 50)">
                  <circle cx="0" cy="0" r="6" fill="#7b0000">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="-0.375s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(40 50)">
                  <circle cx="0" cy="0" r="6" fill="#c34c37">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="-0.25s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(60 50)">
                  <circle cx="0" cy="0" r="6" fill="#fd7f64">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="-0.125s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(80 50)">
                  <circle cx="0" cy="0" r="6" fill="#ffbea0">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="0s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
              </svg>
            </div>
            <div
              v-else-if="
                graphData && graphData[0].data && graphData[0].data.length > 0
              "
            >
              <!--begin::Chart-->
              <apexchart
                class="card-rounded-bottom"
                :options="chartOptions"
                :series="graphData"
                type="area"
                height="180"
                width="100%"
              ></apexchart>
            </div>
            <div v-else>
              <div
                class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
              >
                <div class="card-body text-center">
                  <div class="row">
                    <div class="col-sm-12">
                      <h2>{{ $t("analytics.trending_searches.no_result") }}</h2>
                      <p>
                        {{
                          $t("analytics.trending_searches.no_result_subtitle")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Chart-->
          </div>
          <!--end::Body-->
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Content-->
            <div class="d-flex flex-wrap">
              <!--begin::Progress-->
              <div class="flex-row-fluid">
                <h2 class="text-danger">
                  {{
                    parseFloat(
                      searches_without_results.percent_of_no_results
                    ).toLocaleString("cs-CZ", { maximumFractionDigits: 2 })
                  }}%
                </h2>
                <span class="d-block font-weight-bold mb-4">{{
                  $t(
                    "analytics.searches_without_results.list.percent_of_no_results"
                  )
                }}</span>
              </div>
              <!--end::Progress-->
            </div>
            <!--end::Content-->
          </div>
          <!--end::Footer-->
        </div>
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Content-->
            <div class="d-flex flex-wrap">
              <!--begin::Progress-->
              <div class="flex-row-fluid">
                <h2 class="text-danger">
                  {{
                    parseInt(
                      searches_without_results.unique_queries
                    ).toLocaleString()
                  }}
                </h2>
                <span class="d-block font-weight-bold mb-4">{{
                  $t("analytics.searches_without_results.list.search_terms")
                }}</span>
              </div>

              <div class="d-flex flex-column">
                <span class="svg-icon svg-icon-danger svg-icon-5x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,
        4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,
        5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,
        19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        transform="translate(14.701953, 10.701953) rotate(-135.000000)
               translate(-14.701953, -10.701953) "
                      />
                      <path
                        d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,
        4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,
        13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,
        22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </div>
              <!--end::Progress-->
            </div>
            <!--end::Content-->
          </div>
          <!--end::Footer-->
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("analytics.searches_without_results.list.table.title") }}
              </h3>
            </div>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body">
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="searches_without_results.table"
                    :options.sync="options"
                    :loading="loading"
                    :loading-text="
                      $t(
                        'analytics.searches_without_results.list.table.loading'
                      )
                    "
                    :search="search"
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:item.result_setting_type_id="{ item }">
                      {{
                        item.result_setting_type_id == 1
                          ? "Autocomplete"
                          : "Search"
                      }}
                    </template>
                    <template v-slot:item.fixit="{ item }">
                      <fixit_add_dialog
                        v-if="item.fixits == 0"
                        :term="item.term"
                        :callback="reloadData"
                        :buttontitle="
                          $t('search_settings.fixit.buttons.fix_this_problem')
                        "
                        disabled="true"
                      />

                      <router-link
                        v-if="item.fixits > 0"
                        v-slot="{ href }"
                        :to="{
                          name: 'fixit',
                          query: { term: item.term }
                        }"
                      >
                        <a
                          :href="href"
                          class="btn btn-success font-weight-bolder"
                        >
                          {{ $t("search_settings.fixit.buttons.show_fixit") }}
                        </a>
                      </router-link>
                    </template>
                    <template v-slot:item.term="{ item }">
                      <router-link
                        :to="{
                          name: 'trending-searches-item',
                          query: { term: item.term }
                        }"
                      >
                        {{ item.term }}
                      </router-link>
                    </template>
                    <template v-slot:top>
                      <div
                        class="d-flex align-items-center position-relative my-1"
                      >
                        <span
                          class="svg-icon svg-icon-1 position-absolute ms-6"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19
                              11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333
                              17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          v-model="search"
                          :placeholder="
                            $t(
                              'analytics.searches_without_results.list.table.search_field'
                            )
                          "
                          class="form-control form-control-solid w-250px ps-14"
                        />
                      </div>
                    </template>

                    <template v-slot:no-data>
                      {{ $t("common.nodata") }}
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fixit_add_dialog from "../fixit/add_dialog";
import { mapGetters, mapState } from "vuex";
import { LOAD_SEARCHES_WITHOUT_RESULTS } from "@/core/services/store/search_history.module";
import moment from "moment";
import UsStatsConfiguration from "@/view/content/widgets/USStatsConfiguration.vue";

export default {
  components: {
    UsStatsConfiguration,
    fixit_add_dialog},
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: 180,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          sparkline: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#F64E60"],
        stroke: {
          show: true,
          width: 2
        },
        xaxis: {
          type: "datetime"
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            show: true,
            format: "dd. MM h:00",
            formatter: undefined
          },
          y: {
            formatter: function(val) {
              return parseInt(val).toLocaleString();
            }
          }
        }
      },
      options: {},
      search: "",
      headers: [
        { text: "#", value: "row" },
        {
          text: this.$t(
            "analytics.searches_without_results.list.table.headers.result_type"
          ),
          value: "result_setting_type_id"
        },
        {
          text: this.$t(
            "analytics.searches_without_results.list.table.headers.search_query"
          ),
          value: "term"
        },
        {
          text: this.$t(
            "analytics.searches_without_results.list.table.headers.queries"
          ),
          value: "queries"
        },
        {
          text: this.$t(
            "analytics.searches_without_results.list.table.headers.unique_queries"
          ),
          value: "unique_queries"
        },
        {
          text: this.$t(
            "analytics.searches_without_results.list.table.headers.fixit"
          ),
          value: "fixit",
          sortable: false
        }
      ]
    };
  },
  watch: {
    currentUserConfiguration: {
      handler: function () {
        this.reloadData();

      },
      deep: true,
    },
    changeProject() {
      this.reloadData();
    }
  },
  computed: {
    graphData() {
      return [
        {
          name: this.$t(
            "analytics.searches_without_results.list.timeline.data_title"
          ),
          data: this.searches_without_results.timeline.data
        }
      ];
    },
    changeProject() {
      return this.currentProject.token;
    },
    userConf() {
      return this.currentUserConfiguration.stats;
    },
    ...mapState({
      searches_without_results: state =>
        state.searchHistory.tables.searches_without_results,
      loading: state => state.searchHistory.loading
    }),
    ...mapGetters(["currentProject","currentUserConfiguration"])
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData() {
      const data = [];
      if (this.userConf.result_object === "search" || this.userConf.result_object === "all") {
        data.push("result_setting_type_id[]=2");
      }
      if (this.userConf.result_object === "autocomplete" || this.userConf.result_object === "all") {
        data.push("result_setting_type_id[]=1");
      }

      if (this.userConf.date_range.startDate) {
        data.push(
          `start=${moment(this.userConf.date_range.startDate).format("yyyy-MM-DD")}`
        );
      }

      if (this.userConf.date_range.endDate) {
        data.push(`end=${moment(this.userConf.date_range.endDate).format("yyyy-MM-DD")}`);
      }
      if (this.userConf.language) {
        data.push(`language=${this.userConf.language}`);
      }

      this.$store.dispatch(LOAD_SEARCHES_WITHOUT_RESULTS, data.join("&"));
    }
  }
};
</script>
